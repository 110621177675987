import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModDotList from "../../../components/Chronology/Modules/ModDotList";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDailyHighlight from "../../../components/Chronology/Modules/ModDailyHighlight";
import { StrongGold } from "../../../components/Textstyles/Styles";

export const frontmatter = {
  title: "Día 47",
  week: "Semana 7",
  month: "abr",
  day: "29",
  monthNumber: "04",
  date: "2020-04-29",
  path: "/cronologia/semana-07#dia-29-abr",
};

const Day47 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDataEu fecha={"2020-04-29"} />
        <ModLethalityEu fecha={"2020-04-29"} numPaises={8} />
        <ModCovidHighlight>
          Estados Unidos alcanza 1.000.000 de casos confirmados de covid-19
        </ModCovidHighlight>
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 3.980 nuevos positivos y 453 personas fallecidas.
          Permanecen hospitalizadas 115.845 personas, 886 más que el día
          anterior, y 6.399 reciben el alta. El número de casos ingresados en la
          UCI asciende a 10.721, lo que supone un aumento de 284 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 10,3% y la tasa de recuperados del 46,1%.
        </ModText>
        <ModDailyHighlight>
          <strong>Primer día sin fallecidos</strong> con COVID-19 en la región
          de <StrongGold>Murcia</StrongGold>.
        </ModDailyHighlight>
        <ModText>
          <strong>El impacto del COVID-19 entre los reclusos</strong> de las
          cárceles de Instituciones Penitenciarias es{" "}
          <strong>4 veces inferior</strong> que en la población general: el
          número total de internos con PCR positiva es de 52, lo que supone un
          tasa de afectación de aproximadamente 1,08 por mil internos, mientras
          que entre la población general esa tasa es de 4,3 por mil habitantes.
        </ModText>
        <ModText>
          El Centro para el Desarrollo Tecnológico Industrial (CDTI) aprueba{" "}
          <strong>4 nuevos proyectos tecnológicos frente a COVID-19</strong>, y
          estarán relacionados con la fabricación de vacunas y mascarillas,
          análisis clínicos y diagnóstico e investigación vírica.
        </ModText>
        <ModImage src="/images/svg/29_abr_cargueros.svg" alt="buques de carga" />
        <ModText>
          Hoy se ha publicado en el Boletín Oficial del Estado una Orden que
          establece la documentación que acredita a los tripulantes de los
          buques para facilitar su circulación, a fin de asegurar la prestación
          de los servicios de transporte marítimo por la crisis del #COVID19.
        </ModText>
        <ModImage
          src="/images/svg/1004-military-red-cross.svg"
          alt="Operación Balmis contra el covid-19"
        />
        <ModText>
          Hasta el momento, un total de 149.334 militares han participado en la{" "}
          <strong>Operación Balmis</strong>. Las actuaciones que han llevado a
          cabo durante 44 días han sido las siguientes:
        </ModText>
        <ModDotList
          item1="18.010 Intervenciones
          totales"
          item2="10.253 Desinfecciones
          totales"
          item3="4.937 Intervenciones
          residencias"
          item4="2.179 Poblaciones
          distintas"
          item5="3.044 Hospitales y
          centros de salud"
          item6="1.072 Intervenciones en centros sociales"
        />
      </ContentRight>
    </LayoutDay>
  );
};
export default Day47;
